import { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import closeIcon from "../../assets/close.svg";
import logoGoogle from "../../assets/logo_google.svg";
import styles from "./popup.module.css";
import { Button, EButtonType } from "../button/button";

export type TGame = {
  clientId: string;
  name: string;
  iconSrc: string;
};

enum EDeletionStep {
  INITIAL,
  CONFIRM,
  SUCCESS,
  LOADING,
  ERROR,
  NO_GPLAY_ID,
}

type TDeletionData = {
  date: string;
  googlePlayId: string;
  game: string;
  userProvidedId: string;
};

interface PopupProps {
  popupRef: React.RefObject<HTMLDivElement>;
  popupGame: TGame;
  handleClosePopup: () => void;
}

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const Popup: React.FC<PopupProps> = ({
  popupRef,
  popupGame,
  handleClosePopup,
}) => {
  const [deletionStep, setDeletionStep] = useState<EDeletionStep>(
    EDeletionStep.INITIAL
  );
  const [deletionData, setDeletionData] = useState<TDeletionData | null>(null);
  const [userProvidedId, setUserProvidedId] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (userProvidedId.trim() !== "") {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [userProvidedId]);

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      const accessToken = `Bearer ${res.access_token}`;

      isDev && console.log("Login successful, access token:", res.access_token);

      try {
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            method: "GET",
            headers: {
              Authorization: accessToken,
            },
          }
        );
        const userInfo = await userInfoResponse.json();

        isDev && console.log("User info:", userInfo);

        const playGamesResponse = await fetch(
          "https://www.googleapis.com/games/v1/players/me",
          {
            method: "GET",
            headers: {
              Authorization: accessToken,
            },
          }
        );
        const playGamesResponseBody = await playGamesResponse.text();

        isDev &&
          console.log(
            "Google Play Games response status:",
            playGamesResponse.status
          );

        isDev &&
          console.log(
            "Google Play Games response body:",
            playGamesResponseBody
          );

        if (!playGamesResponse.ok) {
          setDeletionStep(EDeletionStep.NO_GPLAY_ID);
          return;
        }

        const playGamesData = JSON.parse(playGamesResponseBody);

        isDev && console.log("Google Play Games data:", playGamesData);

        setDeletionData({
          date: new Date().toString(),
          googlePlayId: playGamesData ? playGamesData.playerId : "No Google Play ID",
          game: popupGame.name,
          userProvidedId: "", // Initialize with empty string
        });

        setDeletionStep(EDeletionStep.CONFIRM);
      } catch (error) {
        console.error("Error during API calls:", error);
        setDeletionStep(EDeletionStep.ERROR);
      }
    },
    onError: (error) => {
      console.error("Login error:", error);
      setDeletionStep(EDeletionStep.ERROR);
    },
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/games openid https://www.googleapis.com/auth/userinfo.profile",
  });

  const handleAuth = () => {
    setDeletionStep(EDeletionStep.LOADING);
    login();
  };

  const handleSendData = async () => {
    setDeletionStep(EDeletionStep.LOADING);
    const url =
      "https://script.google.com/macros/s/AKfycbwwPCu_bgW5xezEzHMLs9qa7aJVyQKPe2irzHBYjuRPmBrEGRkC7O1dAnvgyx0OJOxW/exec";
    const data = new FormData();
    if (deletionData) {
      data.append("date", deletionData.date);
      data.append("googlePlayId", deletionData.googlePlayId);
      data.append("game", deletionData.game);
      data.append("userProvidedId", userProvidedId);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      console.log("Data sent to Google Sheets, response:", response);
      setDeletionStep(EDeletionStep.SUCCESS);
    } catch (error) {
      console.error("Error sending data to Google Sheets:", error);
      setDeletionStep(EDeletionStep.ERROR);
    }
  };

  const renderPopupContent = () => {
    switch (deletionStep) {
      case EDeletionStep.NO_GPLAY_ID:
        return (
          <>
            <h3 className={styles.popupHeading}>
              Deleting {popupGame.name} account
            </h3>
            <p style={{ textAlign: "center" }}>
              No games associated with this Google Play account. Please try another account.
            </p>
            <div className={styles.actionBtnWrapper}>
              <Button
                onClick={handleClosePopup}
                btnText="Close"
                type={EButtonType.SECONDARY}
              />
            </div>
          </>
        );
      case EDeletionStep.LOADING:
        return (
          <>
            <h3 className={styles.popupHeading}>
              Deleting {popupGame.name} account
            </h3>
            <div className={styles.popupLoader}></div>
          </>
        );
      case EDeletionStep.ERROR:
        return (
          <>
            <h3 className={styles.popupHeading}>
              Deleting {popupGame.name} account
            </h3>
            <p style={{ textAlign: "center" }}>
              Oops, something went wrong. Please try later.
            </p>
            <div className={styles.actionBtnWrapper}>
              <Button
                onClick={handleClosePopup}
                btnText="Close"
                type={EButtonType.SECONDARY}
              />
            </div>
          </>
        );
      case EDeletionStep.INITIAL:
        return (
          <>
            <h3 className={styles.popupHeading}>
              Delete my {popupGame.name} account
            </h3>
            <h4 className={styles.popupSubHeading}>What will be deleted?</h4>
            <p>
              Your account linked with the profile and all associated data. Any
              local progress stored on your device will not be deleted – only
              data stored remotely.
            </p>
            <p>
              Please sign in to the provider that was used to sign up for your{" "}
              {popupGame.name} account:
            </p>
            <div className={styles.actionBtnWrapper}>
              <Button
                onClick={handleAuth}
                btnText="Sign in with Google"
                type={EButtonType.SIGN_IN}
                icon={logoGoogle}
              />
            </div>
          </>
        );
      case EDeletionStep.CONFIRM:
        return (
          <>
            <h3 className={styles.popupHeading}>
              Are you sure you want to delete your {popupGame.name} account?
            </h3>
            <p style={{ textAlign: "center" }}>
              You've been logged in with Google
            </p>
            <p style={{ textAlign: "center" }}>
              Please enter your Player ID. You can find it in the Settings screen
            </p>            
            <input
              type="text"
              value={userProvidedId}
              onChange={(e) => setUserProvidedId(e.target.value)}
              placeholder="Player ID"
              className={styles.inputField}
            />
            <div className={styles.actionBtnWrapper}>
              <Button
                onClick={handleClosePopup}
                btnText="No, I have changed my mind"
                type={EButtonType.PRIMARY}
              />
              <Button
                onClick={handleSendData}
                btnText="Yes, delete my account"
                type={EButtonType.SECONDARY}
                disabled={isButtonDisabled}
              />
            </div>
          </>
        );
      case EDeletionStep.SUCCESS:
        return (
          <>
            <h3 className={styles.popupHeading}>
              Your {popupGame.name} account has been scheduled for deletion
            </h3>
            <svg
              width="58"
              height="58"
              viewBox="0 0 58 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2492_3622)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29 0C13.0047 0 0 13.0047 0 29C0 44.9953 13.0047 58 29 58C44.9953 58 58 44.9953 58 29C58 13.0047 44.9953 0 29 0Z"
                  fill="#85C800"
                />
                <path
                  d="M23.4152 42.2654C19.7109 38.5611 16.0293 34.8229 12.3137 31.1186C11.9059 30.7107 11.9059 30.0311 12.3137 29.6232L16.5844 25.3525C16.9922 24.9447 17.6719 24.9447 18.0797 25.3525L24.1855 31.4584L39.8863 15.7463C40.3055 15.3385 40.9738 15.3385 41.393 15.7463L45.675 20.0283C46.0941 20.4475 46.0941 21.1158 45.675 21.5236L24.9105 42.2654C24.5027 42.6846 23.8344 42.6846 23.4152 42.2654Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2492_3622">
                  <rect width="58" height="58" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p style={{ textAlign: "center" }}>
              Deletion may take up to 30 days to be complete
            </p>
            <div className={styles.actionBtnWrapper}>
              <Button
                onClick={handleClosePopup}
                btnText="Delete another account"
                type={EButtonType.SECONDARY}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className={styles.popup}>
      <div ref={popupRef} className={styles.popupInner}>
        <img
          src={popupGame.iconSrc}
          alt={popupGame.name}
          className={styles.popupGameIcon}
        />
        <button className={styles.popupCloseBtn} onClick={handleClosePopup}>
          <img src={closeIcon} alt="close" />
        </button>
        <div className={styles.popupContent}>{renderPopupContent()}</div>
      </div>
    </div>
  );
};
