import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { Footer } from "./components";

import logo from "./assets/logo.svg";

import styles from "./styles/app.module.css";
import { Popup, TGame } from "./components/popup/popup";
import useWindowDimensions from "./hooks";

import beIcon from "./assets/be_icon.png";
import catsIcon from "./assets/CATS_icon.png";
import kotIcon from "./assets/King_of_thieves_icon.png";
// import otIcon from "./assets/overcrowded_icon.png";

const bulletEchoClientId = process.env
  .REACT_APP_BULLET_ECHO_G_CLIENT_ID as string;
const ktClientId = process.env.REACT_APP_KT_G_CLIENT_ID as string;
const catsClientId = process.env.REACT_APP_CATS_G_CLIENT_ID as string;

const games: Array<TGame> = [
  {
    clientId: bulletEchoClientId,
    name: "Bullet Echo",
    iconSrc: beIcon,
  },
  {
    clientId: catsClientId,
    name: "CATS: Crash Arena Turbo Stars",
    iconSrc: catsIcon,
  },
  { clientId: ktClientId, name: "King of Thieves", iconSrc: kotIcon },
];

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupGame, setPopupGame] = useState<TGame>({
    clientId: "",
    name: "",
    iconSrc: "",
  });
  const [isVerticalLayout, setIsVerticalLayout] = useState<boolean>(false);
  const popupRef = useRef<any>(null);
  const { width } = useWindowDimensions();

  const handleOpenPopup = (game: TGame) => {
    setPopupGame(game);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    setIsVerticalLayout(width <= 1199);
  }, [width]);

  useEffect(() => {
    showPopup
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [showPopup]);

  useEffect(() => {
    const handleEscClosePopup = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClosePopup();
      }
    };
    window.addEventListener("keydown", handleEscClosePopup);
    return () => window.removeEventListener("keydown", handleEscClosePopup);
  }, []);

  return (
    <GoogleOAuthProvider clientId={popupGame.clientId}>
      <div className={styles.app}>
        <main className={styles.content}>
          <section className={styles.leftPanel}>
            <div className={styles.logo}>
              <img src={logo} alt="zeptolab" />
            </div>
            <h1 className={styles.mainHeading}>
              <span className={styles.mainHeadingLine}>Choose the game</span>{" "}
              <span className={styles.mainHeadingLine}>to delete the</span>{" "}
              <span className={styles.mainHeadingLine}>account for:</span>{" "}
            </h1>
            <div className={styles.infoBlock}>
              <h2 className={styles.secondaryHeading}>
                BY DELETING YOUR ACCOUNT
              </h2>
              <p className={styles.text}>
                we permanently erase your game progress and all associated
                information
              </p>
            </div>
            {isVerticalLayout ? null : (
              <div className={styles.infoBlock}>
                <p className={styles.text}>
                  You have separate accounts for each ZeptoLab game you have
                  played. If you wish to delete all your accounts, each one must
                  be deleted separately.
                </p>
              </div>
            )}
          </section>
          <section className={styles.rightPanel}>
            <div
              className={clsx({
                [styles.gameListWrapper]: true,
                [styles.multiple]: games.length > 4,
              })}
            >
              <ul className={styles.gameList}>
                {games.map((item: TGame) => {
                  return (
                    <li className={styles.gameItem} key={item.name}>
                      <img
                        src={item.iconSrc}
                        alt={item.name}
                        className={styles.gameItemIcon}
                      />
                      <div className={styles.gameItemContent}>
                        <span className={styles.gameItemName}>{item.name}</span>
                        <button
                          onClick={() => handleOpenPopup(item)}
                          className={styles.gameItemButton}
                        >
                          DELETE ACCOUNT
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
          {isVerticalLayout ? (
            <div className={styles.secondInfoBlockMobilePortal}>
              <div className={styles.infoBlock}>
                <p className={styles.text}>
                  You have separate accounts for each ZeptoLab game you have
                  played. If you wish to delete all your accounts, each one must
                  be deleted separately.
                </p>
              </div>
            </div>
          ) : null}
        </main>

        <Footer />
        {showPopup ? (
          <Popup
            popupRef={popupRef}
            popupGame={popupGame}
            handleClosePopup={handleClosePopup}
          />
        ) : null}
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
