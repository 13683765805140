import clsx from "clsx";
import styles from "./button.module.css";

export enum EButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SIGN_IN = "signin",
}

type TButtonProps = {
  btnText: string;
  onClick: () => void;
  type: EButtonType;
  icon?: string;
  disabled?: boolean;
};

export const Button: React.FC<TButtonProps> = ({
  btnText,
  onClick,
  type,
  icon,
  disabled = false, // Set default value
}) => {
  return (
    <button
      className={clsx({
        [styles.button]: true,
        [styles.primary]: type === EButtonType.PRIMARY,
        [styles.secondary]: type === EButtonType.SECONDARY,
        [styles.signIn]: type === EButtonType.SIGN_IN,
      })}
      onClick={onClick}
      disabled={disabled} // Apply the disabled attribute
    >
      {icon ? <img src={icon} alt="google" /> : null}
      {btnText}
    </button>
  );
};
