import footerLogo from "../../assets/footer_logo.svg";
import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <a href="https://www.zeptolab.com">
       <img src={footerLogo} alt="zepto lab" className={styles.footerLogo} />
      </a>
      <nav className={styles.footerNav}>
        <a className={styles.footerNavLink} href="https://www.zeptolab.com/terms">
          Terms and conditions
        </a>
        <a className={styles.footerNavLink} href="https://www.zeptolab.com/privacy">
          Privacy policy
        </a>
        <a className={styles.footerNavLink} href="https://www.zeptolab.com/privacy_ctr3">
          Privacy policy for Cut the Rope 3
        </a>
        <a className={styles.footerNavLink} href="/">
          
        </a>
      </nav>
      <div className={styles.socialsAndCopyWrapper}>
        <nav className={styles.footerSocials}>
          <a href="https://www.facebook.com/zeptolab" className={styles.footerSocialLink}>
            Facebook
          </a>
          <a href="https://www.instagram.com/zeptolab/" className={styles.footerSocialLink}>
            Instagram
          </a>
          <a href="https://twitter.com/ZeptoLab" className={styles.footerSocialLink}>
            X
          </a>
          <a href="https://www.linkedin.com/company/zeptolab" className={styles.footerSocialLink}>
            LinkedIn
          </a>
        </nav>
        <div className={styles.copyright}>
          © 2013 – 2024 ZeptoLab UK Ltd. All rights reserved.
        </div>
      </div>
    </footer>
  );
};
